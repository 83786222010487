
/* d-block position-relative */
.swiper-slide.flex {
    width: auto;
    display: flex;
    height: auto;
}
.swiper-slide div.content {
    width:100%;
}
.swiper-slide a.background-picture {
    position:absolute;
    left: 0px;
    top: 0px;
    width: 100%;
}
.swiper-slide:not(.flex) img.background-picture {
    width: 100%;
}



